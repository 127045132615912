import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let toastr: any;
declare let moment: any;
declare let ICAL: any;
declare let Chart: any;
declare let Stripe: any;
// declare let gtag: any;

declare let LANG: any;
declare let LG: any;

@Component({
  selector: 'app-nueva-resera',
  templateUrl: './nueva-resera.component.html',
  styleUrls: ['./nueva-resera.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NuevaReseraComponent implements OnInit {
  public LG:any = LG;
  public aforo = [];
  public evento:any = {};
  public eventos:any = [];
  public eventostodos:any = [];
  public reservasDias = [];
  public mesesAforo:any = [];
  public historicoReservas:any = [];
  public actividades:any = [];
  public idCalendario:any;
  public primeraCargaCalendario = true;
  public sesiones:any = [];
  public aforoDia:any = [];
  public dataReserva:any = {
    id_evento:    '',
    fecha:        '',
    sesion:       '',
    adultos:      0,
    ninios:       0,
    niniosGratis: 0,
    nombre:       '',
    apellidos:    '',
    email:        '',
    telefono:     '',
    precio:       0,
    cli_localidad:'',
    tipoPago:     '',
    tipo:         'Web',
    idioma:       'es'
  };
  public dataPersonaRegala:any = {
    nombre:       '',
    apellidos:    '',
    email:        '',
    telefono:     '',
    codpostal:    '',
    dedicatoria:  ''
  };
  public dataCanjearBono:any = {
    nombre:       '',
    apellidos:    '',
    email:        '',
    telefono:     '',
    codpostal:    '',
    dedicatoria:  ''
  };
  public precioAdulto:any = 0;
  public precioNinio:any = 0;
  public precioCalculoAdulto:any = 0;
  public precioCalculoNinio:any = 0;
  public plazasDisponibles:any = 50;

  public formas_pago:any = [];
  public forma_pago_seleccionada:any = null;
  public stripe:any = null;
  public stripe_card:any = null;
  public num_reservas_dias_check = 0;

  public url_politicas_privacidad:any = '';
  public anticipo:any = 100;
  public reservar:boolean = true;
  public regalar:boolean = false;
  public canjear:boolean = false;

  public google_analytics:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    setTimeout(() => {
      this.idCalendario = 1;
      this.dataReserva.id_evento = this.idCalendario;
      this.LoadAforo();
      var formasPago = $('.formas-pago>input');
      $(formasPago[0]).click();
    }, 100);

    LANG = this.globals.GetQueryString('lang');
    if (!LANG || LANG == '') LANG = 'es';
    this.dataReserva.idioma = LANG;
    // console.log(this.globals);

    if(this.globals.api_url == this.globals.entornos.birding){
      this.google_analytics.tagmanagerga = `<!-- Google Tag Manager -->
        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-WFMX9MW3');</script>
        <!-- End Google Tag Manager -->`;
      $('head').append(this.google_analytics.tagmanagerga);
      this.google_analytics.tagmanagergabody = `<!-- Google Tag Manager (noscript) -->
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WFMX9MW3"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
        <!-- End Google Tag Manager (noscript) -->`;
      $('body').prepend(this.google_analytics.tagmanagergabody);
    }

    this.loadActividades();
    let that = this;
    setTimeout(() => {
      $('#selector-actividades-calendario').val('5');
      $('#selector-actividades-calendario').change(function(){
        $.LoadingOverlay("show", {
          background: 'rgba(255, 255, 255, 0.6)',
          imageResizeFactor: 0.5
        });
        that.idCalendario = $(this).val();
        that.LoadAforo();
      });
      $(document).on('click', '#custom-menos-adulto', function(event) {
        if(!$(event.target).hasClass('disabled-custom-input')){
          if(that.dataReserva.adultos > 0){
            that.dataReserva.adultos--;
          }
          if(that.dataReserva.adultos == 0){
            $(event.target).addClass('disabled-custom-input');
          }
          that.calcularPrecioModal();
          if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $(document).on('click', '#custom-mas-adulto', function(event) {
        if(!$(event.target).hasClass('disabled-custom-input')){
          if(that.plazasDisponibles > 0){
            that.dataReserva.adultos++;
          }
          if((that.dataReserva.adultos+that.dataReserva.ninios) >= 6 && that.globals.api_url == that.globals.entornos.jalama){
            // $(this).addClass('disabled-custom-input');
            $('#custom-mas-adulto').addClass('disabled-custom-input');
            $('#custom-mas-ninio').addClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
            that.calcularPrecioModal();
            return;
          }
          $('#custom-menos-adulto').removeClass('disabled-custom-input');
          that.calcularPrecioModal();
          if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $(event.target).addClass('disabled-custom-input');
            $('#custom-mas-ninio').addClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
          }else{
            $(event.target).removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $(document).on('click', '#custom-menos-ninio', function(event) {
        if(!$(event.target).hasClass('disabled-custom-input')){
          if(that.dataReserva.ninios > 0){
            that.dataReserva.ninios--;
          }
          if(that.dataReserva.ninios == 0){
            $(event.target).addClass('disabled-custom-input');
          }
          that.calcularPrecioModal();
          if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $(document).on('click', '#custom-mas-ninio', function(event) {
        if(!$(event.target).hasClass('disabled-custom-input')){
          if(that.plazasDisponibles > 0){
            that.dataReserva.ninios++;
          }
          if((that.dataReserva.adultos+that.dataReserva.ninios) >= 6 && that.globals.api_url == that.globals.entornos.jalama){
            // $(this).addClass('disabled-custom-input');
            $('#custom-mas-adulto').addClass('disabled-custom-input');
            $('#custom-mas-ninio').addClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
            that.calcularPrecioModal();
            return;
          }
          $('#custom-menos-ninio').removeClass('disabled-custom-input');
          that.calcularPrecioModal();
          if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $(event.target).addClass('disabled-custom-input');
            $('#custom-mas-adulto').addClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
          }else{
            $(event.target).removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-menos-ninio-gratis').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          if(that.dataReserva.niniosGratis > 0){
            that.dataReserva.niniosGratis--;
          }
          if(that.dataReserva.niniosGratis == 0){
            $(this).addClass('disabled-custom-input');
          }
          if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
          }
        }
      });
      $('#custom-mas-ninio-gratis').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          if(that.plazasDisponibles > 0){
            that.dataReserva.niniosGratis++;
          }
          $('#custom-menos-ninio-gratis').removeClass('disabled-custom-input');
          if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $(this).addClass('disabled-custom-input');
            $('#custom-mas-ninio').addClass('disabled-custom-input');
            $('#custom-mas-adulto').addClass('disabled-custom-input');
          }else{
            $(this).removeClass('disabled-custom-input');
            $('#custom-mas-ninio').removeClass('disabled-custom-input');
            $('#custom-mas-adulto').removeClass('disabled-custom-input');
          }
        }
      });
    }, 1000);
    $('#modal-reservar-calendar').modal('show');
    $('body').removeClass('modal-open');
    this.globals.Api('/pub-formas-pago',{idioma: LANG}).subscribe(data => {
      if (!data || !data.length) return;
      this.formas_pago = data;
    });
    this.globals.Api('/pub-politicas-privacidad').subscribe(data => {
      if (!data || data.error) {
        return;
      }
      this.url_politicas_privacidad = data;
    });
    this.IframePostMessages();
    window.onresize = () => {
      this.IframePostMessages();
    };

    this.globals.Api('/eve-eventos', {web: 1}).subscribe(data => {
      if (!data || data.error) { return; }
      this.eventostodos = data;
    });
  }

  IframePostMessages() {
    setTimeout(() => {
      window.parent.postMessage({
        url: window.location.href,
        height: $('#content-iframe-frontend').height()
      }, '*');
      window.postMessage({
        url: window.location.href,
        height: $('#content-iframe-frontend').height()
      }, '*');
    }, 100);
  }

  //Checkeamos si hay aforos configurados
  LoadAforo(){
    this.globals.Api('/pub-config-anticipo').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar anticipo', 'error');return;
      }
      this.anticipo = data;
    });
    this.globals.Api('/eve-eventos',{'id': this.idCalendario}).subscribe(data => {
      if (!data || !data.length) {
        // swal('Error', 'Error al validar el aforo', 'error');
        return;
      }
      // this.sesiones = data[0].sesiones.split(',');
      this.sesiones.pop();
      this.precioAdulto = data[0].precio;
      this.precioNinio = data[0].precio_ninios;
    });
    this.globals.Api('/pub-eve-eventos-aforo-v2-calendario',{'id_evento': this.idCalendario, 'oculto': true}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      this.aforo = data;
      // console.log(this.aforo);
      var month = '';
      this.aforo.forEach(item =>{
        item.percentage = (100 * item.aforo_restante) / item.aforo_total;
        var dt = new Date(item.fecha);
        var monthYear:any = {};
        item.mes = dt.toLocaleString('es-ES', { month: 'long' });
        monthYear.month = dt.toLocaleString('es-ES', { month: 'long' });
        monthYear.year = item.anio = dt.getFullYear();
        if(month != monthYear.month){
          month = monthYear.month;
          this.mesesAforo.push(monthYear);
        }
      });
      if(this.primeraCargaCalendario){
        this.CargarCalendario();
        this.primeraCargaCalendario = false;
      }else{
        //this.CargarEventosCalendario();
        $.LoadingOverlay("hide");
      }
    });
  }

  CargarCalendario() {
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    let that = this;
    var heightCalendar = 280;
    if($(window).width() <= 1366 && $(window).width() > 1025){
      heightCalendar = 325;
    }
    $('#calendar').fullCalendar({
        header: {
            left: 'title',
            center: '',
            right: 'today month prev,next'
        },
        defaultView: 'month',
        defaultDate: new Date(),
        locale: LANG,
        allDaySlot: false,
        editable: false,
        droppable: false,
        selectable: false,
        duration: false,
        contentHeight: heightCalendar,
        // //timeFormat: 'H:mm',
        // minTime: '08:00:00',
        // maxTime: '22:00:00',
        viewRender: (view, element) => {
          // this.params.fecha_inicio = view.start.format('YYYY-MM-DD');
          // this.params.fecha_fin = view.end.format('YYYY-MM-DD');
          // this.CargarReparaciones();
          var fechaInicioRender = view.start.format('YYYY-MM-DD');
          var fechaFinRender = view.end.format('YYYY-MM-DD');

          /*Aquí para cargar los datos al calendario*/
          that.CargarEventosCalendario(fechaInicioRender, fechaFinRender);
          $('.fc-day-top').mouseenter(function(){
            $('.content-hover-aforo').remove();
            //console.log('Mouse enter');
            var fechaDiaHover = $(this).attr('data-date');
            // console.log(fechaDiaHover);
            var aforoDia = [];
            var bloqueado = true;
            var sesionesDiaSelect = [];
            that.aforo.forEach(element => {
              if(element.ocultar == 1){
                return;
              }
              if(element.fecha == fechaDiaHover){
                that.sesiones.forEach(element2 => {
                  if(element.sesion == element2){
                    aforoDia.push(element.aforo_restante);
                    sesionesDiaSelect.push(element.sesion);
                    bloqueado = false;
                  }
                });
              }
            });
            if(bloqueado) return;
            // console.log(aforoDia);

            var htmlAforo = `<div class="content-hover-aforo display-flex style="padding-left: 10px; padding-right: 10px;">`;
            for(var i = 0; i < aforoDia.length; i++){
              if(aforoDia[i] <= 10){
                htmlAforo += `<div style="padding-bottom: 10px; width: 50%; padding-top: 10px;">`+sesionesDiaSelect[i]+` <br/><span>`+aforoDia[i]+` plazas</span></div>`;
              }else{
                htmlAforo += `<div style="padding-bottom: 10px; width: 50%; padding-top: 10px;">`+sesionesDiaSelect[i]+`</div>`;
              }
            }
            htmlAforo += `</div>`;
            $(this).append(htmlAforo);
            $('.fc-day-grid.fc-unselectable>div:first-child .content-hover-aforo').addClass('bottom-hover');
            $('.fc-day-grid.fc-unselectable>div:first-child:hover').addClass('z-index-9999');
            $('.fc-day-grid.fc-unselectable>div:nth-child(2) .content-hover-aforo').addClass('bottom-hover');
            $('.fc-day-grid.fc-unselectable>div:nth-child(2):hover').addClass('z-index-9999');
          });
          $('.fc-day-top').mouseleave(function(){
            $('.content-hover-aforo').remove();
            $('.fc-day-grid.fc-unselectable>div:first-child').removeClass('z-index-9999');
            $('.fc-day-grid.fc-unselectable>div:nth-child(2)').removeClass('z-index-9999');
          });
          // console.log('renderizado');
          $('.fc-day-top.fc-today,.fc-day-top.fc-future').click(function(){
            $('.fc-day-top.fc-today,.fc-day-top.fc-future').css('background-color','');
            $('.fc-day-top.fc-today,.fc-day-top.fc-future').css('color','');
            $(this).css('background-color','#7AAC3F');
            $(this).css('color','white');
          });
        },
        eventRender: function(event, element) {
        },
        eventClick: function(info) {
          if (info.disabled) return;
          $('.fc-event').removeClass('checked');
          $(this).addClass('checked');
        },
        dayClick: function(date, allDay, jsEvent, view) {
          that.evento.id = null;
          if (that.canjear && that.dataCanjearBono && that.dataCanjearBono.bono) {
            that.evento.id = that.dataCanjearBono.bono.id_actividad;
          }
          that.sesiones = [];
          that.dataReserva.id_evento = '';
          that.eventos = [];
          that.globals.Api('/pub-check-dias-minimos').subscribe(data => {
            if (!data || data.error) {
              swal('Error', 'Error al cargar configuración', 'error');return;
            }
            let dateClick = new Date(date).getTime();
            let dateNow = new Date().getTime();
            var someDate = new Date();
            var numberOfDaysToAdd = data.dias_min_reserva;
            var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            let dateMin = new Date(result).getTime();
            let horasMinimas = data.dias_min_reserva * 24;
            if(dateClick < dateNow){
              return;
            }
            if(dateClick <= dateMin || dateClick == dateNow){
              that.sesiones = [];
              swal({
                title: "Información",
                text: "Para reservar con antelación de "+horasMinimas+"h, contacte con nosotros por teléfono "+data.tel_contacto,
                type: "info",
                showCancelButton: true,
                confirmButtonClass: "btn-primary",
                confirmButtonText: "Llamar",
                cancelButtonText: "Cerrar",
                closeOnConfirm: true
              },() => {
                window.open('tel:'+data.tel_contacto);
              });
              return;
            }
            if (that.canjear) {
              that.dataCanjearBono.fecha = moment(date).format('YYYY-MM-DD');
              that.CargarSesionesCanjear();
              return;
            }
            // console.log(that.dataReserva);
            let params = {
              fecha: date,
              fecha_format: moment(date).format('DD/MM/YYYY'),
              fecha_format_en: moment(date).format('YYYY-MM-DD')
            };
            if ($.editcitashow) $.editcitashow(params);

            var today = new Date();
            var tempDate = new Date(date);
            if(today > tempDate){
              return;
            }
            that.dataReserva.fecha = moment(date).format('YYYY-MM-DD');
            $('#date-reservar').val(that.dataReserva.fecha);
            setTimeout(() => {
                that.globals.Api('/eve-eventos',{web: 1, dia: that.dataReserva.fecha}).subscribe(data => {
                if (!data || data.error) {
                  swal('Error', 'Error al cargar datos evento', 'error');return;
                }
                that.eventos = data;
                $('#select-actividad-reservar').off('change');
                $('#select-actividad-reservar').change((e:any) => {
                  let idEventoSelect = $(e.target).val();
                  that.dataReserva.ninios = 0;
                  that.dataReserva.adultos = 0;
                  that.precioCalculoNinio = 0;
                  that.precioCalculoAdulto = 0;
                  that.calcularPrecioModal();
                  $('#custom-menos-adulto').addClass('disabled-custom-input');
                  $('#custom-menos-ninio').addClass('disabled-custom-input');
                  if(idEventoSelect == 'default') return;
                  that.globals.Api('/eve-eventos',{id: idEventoSelect}).subscribe(data => {
                    if (!data || data.error) {
                      swal('Error', 'Error al cargar datos evento', 'error');return;
                    }
                    that.evento = data[0];
                    if(that.evento.vehiculo){
                      // Cálculo disponibilidad condicionante vehículos
                      that.sesiones = null;
                      that.globals.Api('/eve-eventos-sesiones-vehiculos-v2',{id: that.evento.id, fecha: params.fecha_format_en}).subscribe(data => {
                        if (!data || data.error) {
                          swal('Error', 'Error al cargar datos sesiones', 'error');return;
                        }
                        if(data[1].length > 0){
                          // console.log(data[1]);
                          that.sesiones = data[1];
                          that.precioAdulto = data[1][0].precio_adultos;
                          that.precioNinio = data[1][0].precio_ninios;

                        }else{
                          // console.log(data[0]);
                          that.sesiones = data[0];
                          that.precioAdulto = that.evento.precio;
                          that.precioNinio = that.evento.precio_ninios;
                        }
                        var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
                        var d = new Date(params.fecha_format_en);
                        var dayName = days[d.getDay()];
                        that.globals.Api('/check-dia-disponible-v2',{id: that.evento.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
                          if (!data || data.error) {
                            swal('Error', 'Error al cargar datos sesiones', 'error');return;
                          }
                          if(data[0].count == 0 && data[0].dias_especial_disponible == 0){
                            that.sesiones = null;
                          }else{
                            // console.log(that.sesiones);
                            for(let i = 0; i <= that.sesiones.length; i++){
                              // console.log(that.sesiones[i].sesion);
                              let sesion = that.sesiones[i].sesion;
                              // console.log(sesion);
                              that.globals.Api('/eve-eventos-check-vehiculos-v2',{id: that.evento.id, fecha: params.fecha_format_en, sesion: sesion}).subscribe(data => {
                                if (!data || data.error) {
                                  swal('Error', 'Error al verificar vehículos', 'error');return;
                                }
                                // console.log(data);
                                let plazasDisponibles = 0;
                                let plazasDisponiblesSinCoche = that.sesiones[i].aforo + that.sesiones[i].plazas_adicionales;
                                that.sesiones[i].aforo_restante = that.sesiones[i].aforo_restante + that.sesiones[i].plazas_adicionales;
                                data.forEach(vehiculo => {
                                  plazasDisponibles += vehiculo.plazas_disponibles;
                                });
                                // console.log(plazasDisponibles);
                                if(that.sesiones[i].aforo_restante > plazasDisponibles){
                                  that.sesiones[i].aforo_restante = plazasDisponibles;
                                }
                                if(that.sesiones[i].aforo_restante > plazasDisponiblesSinCoche){
                                  that.sesiones[i].aforo_restante = plazasDisponiblesSinCoche;
                                }
                              });
                            }
                          }
                        });
                      });
                    }else{
                      // Cálculo disponibilidad normal sin vehículos
                      that.sesiones = null;
                      that.globals.Api('/pub-eve-eventos-sesiones-v2',{id: that.evento.id, fecha: params.fecha_format_en}).subscribe(data => {
                        if (!data || data.error) {
                          swal('Error', 'Error al cargar datos sesiones', 'error');return;
                        }
                        // console.log(data);
                        if(data[1].length > 0){
                          // console.log(data[1]);
                          that.sesiones = data[1];
                          that.precioAdulto = data[1][0].precio_adultos;
                          that.precioNinio = data[1][0].precio_ninios;

                        }else{
                          // console.log(data[0]);
                          that.sesiones = data[0];
                          that.precioAdulto = that.evento.precio;
                          that.precioNinio = that.evento.precio_ninios;
                        }
                        var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
                        var d = new Date(params.fecha_format_en);
                        var dayName = days[d.getDay()];
                        that.globals.Api('/pub-check-dia-disponible-v2',{id: that.evento.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
                          if (!data || data.error) {
                            swal('Error', 'Error al cargar datos sesiones', 'error');return;
                          }
                          if(data[0].count == 0 && data[0].dias_especial_disponible == 0){
                            that.sesiones = null;
                          }else{
                            for(let i = 0; i <= that.sesiones.length; i++){
                              that.sesiones[i].aforo_restante = that.sesiones[i].aforo_restante + that.sesiones[i].plazas_adicionales;
                              if(that.sesiones[i].aforo_restante < 0) that.sesiones[i].aforo_restante = 0;
                            }
                          }
                        });
                      });
                    }
                  });
                });
              });
            }, 100);
          });
        }
    });
  }

  CargarEventosCalendario(fechaInicio:any, fechaFin:any) {
    $('#calendar').fullCalendar('removeEvents');
    const d = new Date();
    const date1 = new Date(fechaInicio);
    let timestampInicio = Math.floor(date1.getTime()/1000);
    const date2 = new Date(fechaFin);
    const date3 = new Date('2022/12/01');
    let timestampFin = Math.floor(date2.getTime()/1000);
    this.reservasDias = [];

    var dateMs = timestampInicio * 1000;
    var dateOb = new Date(dateMs);
    var year = dateOb.getFullYear();
    var month = ("0" + (dateOb.getMonth() + 1)).slice(-2);
    var date = ("0" + dateOb.getDate()).slice(-2);
    var dateFormattedInicio = year+'-'+month+'-'+date;

    var dateMs2 = timestampFin * 1000;
    var dateOb2 = new Date(dateMs2);
    var year2 = dateOb2.getFullYear();
    var month2 = ("0" + (dateOb2.getMonth() + 1)).slice(-2);
    var dateTwo = ("0" + dateOb2.getDate()).slice(-2);
    var dateFormattedFin = year2+'-'+month2+'-'+dateTwo;

    this.globals.Api('/pub-eve-reservas-calendario-dashboard-v2',{id_evento: this.idCalendario, fecha_inicio: dateFormattedInicio, fecha_fin: dateFormattedFin}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar reservas del calendario', 'error');return;
      }
      this.reservasDias = data;

      this.reservasDias.forEach(reserva => {
        let aforoTotalRestante = parseInt(reserva.aforo_total_actividades) - parseInt(reserva.personas);
        let sumPorcentajeMismaFecha = (aforoTotalRestante * 100) / parseInt(reserva.aforo_total_actividades);

        let start = reserva.fecha;
        let end = reserva.fecha;
        let color = '#5da84e';
        let cursor = 'pointer';
        let textColor = '#FFF';
        let display = 'none';
        let className = 'day-green';
        if(sumPorcentajeMismaFecha < 99){
          color = '#e5b437';
          cursor = 'pointer';
          // className = 'day-yellow';
          if(sumPorcentajeMismaFecha <= 0){
            color = '#a30404';
            cursor = 'default';
            textColor = '#FFF';
            // className = 'day-red';
          }
        }
        let event = {
          id: reserva.fecha,
          className: 'day-reserva',
          title: `${aforoTotalRestante}/${reserva.aforo_total_actividades}`,
          duration: false,
          description: `
            <div style="cursor: ${cursor}">
              <div><b><i class="fa fa-lock" style="display: ${display}; float: left; margin-right: 5px;"></i> Disponiblidad</b><br/><i class="fa fa-user"></i> ${aforoTotalRestante}/${reserva.aforo_total_actividades} <span style="color: white;"><i class="icofont-check-circled"></i></span></div>
            </div>
          `,
          start: start,
          end: end,
          data: reserva.personas,
          //color: color,
          color: color,
          // textColor: textColor,
          textColor: 'transparent',
          disabled: false,
          tipo: 'documento'
        };
        // console.log(event);
        $('#calendar').fullCalendar('renderEvent', event, true);
      });
      $('.fc-row.fc-week.fc-widget-content .fc-content-skeleton tbody td:not(.fc-event-container)').html(`
        <a class="fc-day-grid-event fc-h-event fc-event fc-start fc-end day-reserva" style="background-color:#5da84e;border-color:#5da84e;color:transparent">
          <div class="fc-content">
            <span class="fc-title">0000</span>
          </div>
        </a>
      `);
    this.appref.tick();
    });
  }
  ComprobarGuardarReserva(){
    if(
      this.dataReserva.fecha == null || this.dataReserva.fecha == '' || this.dataReserva.fecha == '0000-00-00' ||
      this.dataReserva.sesion == null || this.dataReserva.sesion == '' ||
      this.dataReserva.adulto == 0 || this.dataReserva.precio == 0 ||
      this.dataReserva.nombre == '' || this.dataReserva.apellidos == '' || this.dataReserva.email == '' || this.dataReserva.telefono == '' ||
      !this.dataReserva.cli_codpostal || this.dataReserva.cli_codpostal == '' || !this.dataReserva.cli_localidad || this.dataReserva.cli_localidad == ''
    ){
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Revisa los datos'), 'error');
      return;
    }
    if(!this.globals.ValidateEmail(this.dataReserva.email)){
      swal('Error', 'Email incorrecto', 'error');return;
    }
    if (
      !$('#check-politicas-privacidad').is(':checked')
    ){
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Política de privacidad', 'Debe marcar que ha leído y acepta la política de privacidad para continuar', 'error');
      return;
    }
    let aforoCheck = (this.dataReserva.adultos + this.dataReserva.ninios + this.dataReserva.niniosGratis);
    $.buttonloading('btn-guardar-reserva', 'Realizando pago ...');
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5,
      text: 'Realizando pago ...'
    });
    let that = this;
    this.dataReserva.cli_tipo_pago = $('input[type="radio"][name="pago"]:checked').val();
    this.dataReserva.cli_tipo_pago_slug = $('input[type="radio"][name="pago"]:checked').attr('id');
    if(this.dataReserva.cli_tipo_pago == undefined){
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', 'Selecciona un tipo de pago', 'warning');return;
    }
    this.GuardarReserva();
  }
  GuardarReserva() {
    this.dataReserva.cli_tipo_pago = $('input[type="radio"][name="pago"]:checked').val();
    this.dataReserva.cli_tipo_pago_slug = $('input[type="radio"][name="pago"]:checked').attr('id');
    this.dataReserva.pagos = [{
      cli_tipo_pago: this.dataReserva.cli_tipo_pago,
      cli_tipo_pago_slug: this.dataReserva.cli_tipo_pago_slug
    }];
    if (this.dataReserva.cupon) {
      this.dataReserva.precio = this.dataReserva.total;
    }

    // if (gtag) {
    //   gtag('event', 'conversion', {'send_to': 'AW-10882867819/poZJCL_e-LUDEOvErcUo',
    //     'value': this.dataReserva.precio,
    //     'currency': 'EUR'
    //   });
    // }

    if (this.forma_pago_seleccionada && this.forma_pago_seleccionada.slug == 'stripe' && this.stripe) {
      let that = this;
      this.stripe.createToken(this.stripe_card).then(function(result) {
        if (result.error) {
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          $.buttoncancelloading('btn-guardar-reserva');
        } else {
          that.dataReserva.stripe_token = result.token.id;
          that.globals.Api('/pub-stripe-pago', that.dataReserva).subscribe(data => {
            if (!data || !data.value) {
              $.LoadingOverlay("hide", true);
              $.buttoncancelloading('btn-guardar-reserva');
              swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');return;
            }
            setTimeout(() => {
              $.LoadingOverlay("hide", true);
              $.buttoncancelloading('btn-guardar-reserva');
              that.dataReserva.stripe_order = data.value;
              that.EnviarDatosReserva();
            }, 200);
          }, error => {
            $.LoadingOverlay("hide", true);
            $.buttoncancelloading('btn-guardar-reserva');
            swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');
          });
        }
      });
      return;
    }
    this.EnviarDatosReserva();
  }
  EnviarDatosReserva() {
    /*-- TEST CONVERSIONES META --*/
    // this.globals.Api('/send-conversion-meta-boton',this.dataReserva).subscribe(data => {
    //   console.log(data);
    //   if (!data || data.error) {
    //     swal('Error', 'Error al enviar la conversión', 'error');
    //     return;
    //   }
    // });
    // $.LoadingOverlay("hide", true);
    // $.buttoncancelloading('btn-guardar-reserva');
    // return;
    /*-- END TEST CONVERSIONES META --*/
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5,
      text: 'Enviando datos ...'
    });
    $.buttonloading('btn-guardar-reserva', 'Enviando datos ...');


    this.globals.Api('/eve-reservas-calendar-add',this.dataReserva).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al hacer la reserva', 'error');
        $('#content-paso-2').hide();
        $('#content-paso-error').fadeIn();
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('btn-guardar-reserva');
        return;
      }
      /*-- TEST CONVERSIONES META --*/
      // this.globals.Api('/send-conversion-meta-boton',this.dataReserva).subscribe(data => {
      //   if (!data || data.error) {
      //     swal('Error', 'Error al enviar la conversión', 'error');
      //     return;
      //   }
      // });
      /*-- END TEST CONVERSIONES META --*/
      window.parent.postMessage('btn-guardar-reserva', '*');
      if (data.form && data.form != '') {
        $('body').append(data.form);
        setTimeout(function() {
            if ($('#paypal-form').length) {
              $('#content-paso-2').hide();
              $('#content-paso-4').fadeIn();
              $('#paypal-form').submit();
              $('#paypal-form').remove();
              let IntervalCompReserva1 = setInterval(()=>{
                this.globals.Api('/pub-get-reserva',{id: data.value}).suscribe(data => {
                  if (!data || data.error) {
                    return;
                  }
                  if(data.id_estado == 3){
                    clearInterval(IntervalCompReserva1);
                    window.parent.postMessage({event_id: 'reserva-confirmada', reserva: this.data}, '*');
                  }
                });
              },3000);
            }
            if ($('#redsys-form').length) {
              $('#content-paso-2').hide();
              $('#content-paso-4').fadeIn();
              $('#redsys-form').submit();
              $('#redsys-form').remove();
              $.LoadingOverlay("hide", true);
              let IntervalCompReserva2 = setInterval(()=>{
                data.value;
                this.globals.Api('/pub-get-reserva',{id: data.value}).suscribe(data => {
                  if (!data || data.error) {
                    return;
                  }
                  if(data.id_estado == 3){
                    clearInterval(IntervalCompReserva2);
                    window.parent.postMessage({event_id: 'reserva-confirmada', reserva: this.data}, '*');
                  }
                });
              },3000);
              // $('#content-paso-2').hide();
              // $('#content-paso-3').fadeIn();
            }
        }, 100);
      } else {
        $.LoadingOverlay("hide", true);
        // swal('¡Reserva realizada con éxito!', 'Recibirá un correo a la dirección facilitada con la confirmación de la reserva.', 'success');
        $('#content-paso-2').hide();
        $('#content-paso-3').fadeIn();
        this.IframePostMessages();
        if (this.forma_pago_seleccionada && this.forma_pago_seleccionada.slug == 'stripe' && this.dataReserva.stripe_order) {
          this.globals.Api('/pub-get-reserva', {stripe_order: this.dataReserva.stripe_order}).subscribe(data => {
            if (!data || !data.id) return;
            this.dataReserva = data;
            this.appref.tick();
          });
        }
        if (this.canjear) {
          this.globals.Api('/pub-get-reserva', {id: data.value}).subscribe((data:any) => {
            if (!data || !data.id) return;
            this.dataReserva = data;
            this.appref.tick();
          });
        }
      }
    }, error => {
      $.LoadingOverlay("hide", true);
      $.buttoncancelloading('btn-guardar-reserva');
    });
  }
  AnteriorModal(){
    $('#content-paso-1').fadeIn();
    $('#content-paso-2').hide();
    this.dataReserva.codigo_cupon = null;
    this.dataReserva.cupon = null;
    this.dataReserva.total = null;
    this.dataReserva.mostracuponok = null;
    this.dataReserva.mostracuponko = null;
    this.IframePostMessages();
  }
  SiguienteModal(){
    // console.log('Click siguiente');
    if(this.num_reservas_dias_check == 0){
      let contTotalPlazas = this.dataReserva.adultos + this.dataReserva.ninios;
      if(this.evento.aforo_minimo > contTotalPlazas){
        swal('¡Atención!', 'Debes de seleccionar un mínimo de '+this.evento.aforo_minimo+' plazas para esta sesión', 'warning');
        return;
      }
    }
    var radiosSeleccion = $('.radios-sesion');
    var checkRadio = false;
    for(var i = 0; i < radiosSeleccion.length; i++){
      if($(radiosSeleccion[i]).is(':checked')){
        checkRadio = true;
      }
    }
    if(this.dataReserva.id_evento == ''){
      swal(LG('¡Atención!'), LG('Debe seleccionar una actividad'), 'warning');
      return;
    }
    if(!checkRadio){
      swal(LG('¡Atención!'), LG('Debe seleccionar una sesión'), 'warning');
      return;
    }
    if(this.dataReserva.adultos == 0){
      swal(LG('¡Atención!'), 'Los niños menores deben de ir acompañados al menos de un adulto', 'warning');
      return;
    }

    $('#content-paso-1').hide();
    $('#content-paso-2').fadeIn();
    window.location.hash = '#content-paso-2';
    if (this.formas_pago && this.formas_pago.length) {
      this.SetFormaPago(this.formas_pago[0]);
    }
    this.IframePostMessages();
  }
  calcularPrecioModal(){
    this.precioCalculoAdulto = this.dataReserva.adultos * this.precioAdulto;
    this.precioCalculoNinio = this.dataReserva.ninios * this.precioNinio;
    this.dataReserva.precio = this.precioCalculoAdulto + this.precioCalculoNinio;
  }
  VolverPaso1(){
    $('#content-paso-3').hide();
    $('#content-paso-1').fadeIn();
    this.dataReserva.codigo_cupon = null;
    this.dataReserva.cupon = null;
    this.dataReserva.total = null;
    this.dataReserva.mostracuponok = null;
    this.dataReserva.mostracuponko = null;
  }
  VolverPaso2(){
    $('#content-paso-error').hide();
    $('#content-paso-2').fadeIn();
  }
  SetFormaPago(item:any) {
    this.forma_pago_seleccionada = item;
    $('#stripe-form #card-element').html('');
    $('#stripe-form #card-errors').html('');
    $('#stripe-form').hide();
    if (!item) return;
    if (item.slug == 'stripe') {
      if (!this.globals.config.pk_test || this.globals.config.pk_test == '') return;
      if (!this.globals.config.pk_live || this.globals.config.pk_live == '') return;
      let that = this;
      if (this.globals.config.st_entorno_pruebas) {
        this.stripe = Stripe(this.globals.config.pk_test);
      } else {
        this.stripe = Stripe(this.globals.config.pk_live);
      }
      var elements = this.stripe.elements();
      $('#stripe-form').show();
      var style = {
        base: {
          fontSize: '16px',
          color: 'var(--color-primary)',
        },
      };
      this.stripe_card = elements.create('card', {style: style, hidePostalCode: true});
      this.stripe_card.mount('#card-element');
    }
  }
  ComprobarCupon() {
    if (!this.dataReserva.codigo_cupon || this.dataReserva.codigo_cupon.trim() == '') return;
    if (!this.dataReserva.email || this.dataReserva.email.trim() == '') return;
    if (!this.dataReserva.precio) return;
    this.dataReserva.cupon = null;
    this.dataReserva.total = null;
    this.dataReserva.precio_original = this.dataReserva.precio;
    clearTimeout($.idtimecupon);
    $.idtimecupon = setTimeout(() => {
      this.globals.Api('/pub-check-cupon', {
        codigo: this.dataReserva.codigo_cupon.trim(),
        cli_email: this.dataReserva.email,
        total: this.dataReserva.precio
      }).subscribe((data:any) => {
        if (!data) return;
        if (data.valido) {
          this.dataReserva.cupon = data.datos;
          this.dataReserva.mostracuponok = true;
          this.dataReserva.mostracuponko = false;
          if (this.dataReserva.cupon.tipo == 'porcentaje') {
            this.dataReserva.total = this.dataReserva.precio - (this.dataReserva.precio * this.dataReserva.cupon.importe / 100);
          } else {
            this.dataReserva.total = this.dataReserva.precio - this.dataReserva.cupon.importe;
          }
        } else {
          this.dataReserva.mostracuponok = false;
          this.dataReserva.mostracuponko = true;
        }
      });
    }, 400);
  }
  setSesionCheck(sesion, aforo_restante){
    //Solo para Canjear
    if (this.canjear) {
      this.dataCanjearBono.puedepaso2 = true;
      this.dataCanjearBono.sesion = sesion;
      let plazasBono:number = this.dataCanjearBono.bono.adultos + this.dataCanjearBono.bono.ninios;
      this.plazasDisponibles = parseInt(aforo_restante);
      if (this.plazasDisponibles < plazasBono) {
        this.dataCanjearBono.puedepaso2 = false;
        this.dataCanjearBono.mensajesesiones = '* No ha plazas disponibles para esta sesión';
      }
      return;
    }
    //****************/
    this.globals.Api('/pub-check-hay-reservas',{id_evento: this.dataReserva.id_evento, sesion: sesion, fecha: this.dataReserva.fecha}).subscribe(data => {
      if(!data || data == ''){
        return;
      }
      // console.log(data);
      this.num_reservas_dias_check = data.num_reservas;
    });
    this.dataReserva.sesion = sesion;
    this.plazasDisponibles = parseInt(aforo_restante);
    if(this.plazasDisponibles < (this.dataReserva.adultos + this.dataReserva.ninios + this.dataReserva.niniosGratis)){
      this.dataReserva.adultos = 0;
      this.dataReserva.ninios = 0;
      this.dataReserva.niniosGratis = 0;
      this.precioCalculoAdulto = 0;
      this.precioCalculoNinio = 0;
      this.calcularPrecioModal();
      $('#custom-mas-adulto').removeClass('disabled-custom-input');
      $('#custom-mas-ninio').removeClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
      $('#custom-menos-adulto').addClass('disabled-custom-input');
      $('#custom-menos-ninio').addClass('disabled-custom-input');
      $('#custom-menos-ninio-gratis').addClass('disabled-custom-input');
    }else{
      $('#custom-mas-adulto').removeClass('disabled-custom-input');
      $('#custom-mas-ninio').removeClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
    }
    if(this.plazasDisponibles == 0){
      $('#custom-mas-adulto').addClass('disabled-custom-input');
      $('#custom-mas-ninio').addClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
      $('#custom-menos-adulto').addClass('disabled-custom-input');
      $('#custom-menos-ninio').addClass('disabled-custom-input');
      $('#custom-menos-ninio-gratis').addClass('disabled-custom-input');
    }
  }
  SeleccionarActividad(){
    this.dataReserva.id_evento = $('#select-actividad-reservar').val();
    if (this.canjear && this.dataReserva.id_evento != '') {
      this.dataCanjearBono.bono.adultos = 0;
      this.dataCanjearBono.bono.ninios = 0;
      $('#custom-mas-adulto').removeClass('disabled-custom-input');
      $('#custom-mas-ninio').removeClass('disabled-custom-input');
      $('#custom-menos-adulto').removeClass('disabled-custom-input');
      $('#custom-menos-ninio').removeClass('disabled-custom-input');
    }
  }
  loadActividades(){
    this.globals.Api('/pub-eve-eventos-dashboard').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar actividades', 'error');return;
      }
      this.actividades = data;
    });
  }
  CheckActividadSelect(){
    if(this.dataReserva.fecha == null || this.dataReserva.fecha == '' || this.dataReserva.fecha == '0000-00-00'){
      swal('Aviso', 'Debe seleccionar previamente una fecha', 'warning');return;
    }
  }
  SetLocalidad(){
    this.globals.Api('/localidad', {codpostal: this.dataReserva.cli_codpostal}).subscribe(data => {
      if (!data || data.error) {
        // swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.dataReserva.cli_localidad = data.localidad;
      this.dataReserva.cli_provincia = data.provincia;
    }, error => {
      // toastr.error(`Al bloquear sesión`, 'Error');
    });
  }
  setReservaRegaloCanjear(idButton) {
    this.evento.id = null;
    this.sesiones = [];
    this.dataReserva.id_evento = '';
    this.eventos = [];
    this.precioAdulto = null;
    this.precioNinio = null;
    this.dataReserva.precio = 0;
    this.dataReserva.fecha = null;
    switch(idButton){
      case 'reservar':{
        this.reservar = true;
        this.regalar = false;
        this.canjear = false;
        $('#calendar').show();
        $('#btn-regalar').removeClass('selected');
        $('#btn-canjear').removeClass('selected');
        $('#btn-reservar').addClass('selected');
        $('#btn-regalar-movil').removeClass('selected');
        $('#btn-reservar-movil').addClass('selected');
        $('#custom-mas-adulto').addClass('disabled-custom-input');
        $('#custom-mas-ninio').addClass('disabled-custom-input');
        $('#custom-menos-adulto').addClass('disabled-custom-input');
        $('#custom-menos-ninio').addClass('disabled-custom-input');
        break;
      }
      case 'regalar':{
        this.reservar = false;
        this.regalar = true;
        this.canjear = false;
        $('#calendar').hide();
        $('#btn-reservar').removeClass('selected');
        $('#btn-canjear').removeClass('selected');
        $('#btn-regalar').addClass('selected');
        $('#btn-reservar-movil').removeClass('selected');
        $('#btn-regalar-movil').addClass('selected');
        this.dataReserva.fecha = this.globals.DateStringEN(new Date().toDateString());
        $('#date-reservar').val(this.dataReserva.fecha);
        let that:any = this;
        $('#select-actividad-reservar').off('change');
        $('#select-actividad-reservar').change(function(){
          let idEventoSelect = $(this).val();
          that.dataReserva.ninios = 0;
          that.dataReserva.adultos = 0;
          that.precioCalculoNinio = 0;
          that.precioCalculoAdulto = 0;
          that.plazasDisponibles = 0;
          that.precioAdulto = 0;
          that.precioNinio = 0;
          that.calcularPrecioModal();
          $('#custom-menos-adulto').addClass('disabled-custom-input');
          $('#custom-menos-ninio').addClass('disabled-custom-input');
          $('#custom-mas-adulto').removeClass('disabled-custom-input');
          $('#custom-mas-ninio').removeClass('disabled-custom-input');
          if (idEventoSelect == 'default') return;
          that.evento = that.eventostodos.find((el:any) => el.id == idEventoSelect);
          if (that.evento) {
            that.plazasDisponibles = that.evento.aforo_predeterminado;
            that.precioAdulto = that.evento.precio;
            that.precioNinio = that.evento.precio_ninios;
          }
          that.appref.tick();
          /*
          that.globals.Api('/eve-eventos', {id: idEventoSelect}).subscribe(data => {
            if (!data || data.error) {
              swal('Error', 'Error al cargar datos evento', 'error');return;
            }
            if (that.evento.vehiculo) {
              // Cálculo disponibilidad condicionante vehículos
              that.sesiones = null;
              that.globals.Api('/eve-eventos-sesiones-vehiculos-v2',{id: that.evento.id, fecha: that.dataReserva.fecha}).subscribe(data => {
                if (!data || data.error) {
                  swal('Error', 'Error al cargar datos sesiones', 'error');return;
                }
                if(data[1].length > 0){
                  // console.log(data[1]);
                  that.sesiones = data[1];
                  that.precioAdulto = data[1][0].precio_adultos;
                  that.precioNinio = data[1][0].precio_ninios;

                }else{
                  // console.log(data[0]);
                  that.sesiones = data[0];
                  that.precioAdulto = that.evento.precio;
                  that.precioNinio = that.evento.precio_ninios;
                }
              });
            }else{
              // Cálculo disponibilidad normal sin vehículos
              that.sesiones = null;
              that.globals.Api('/pub-eve-eventos-sesiones-v2',{id: that.evento.id, fecha: that.dataReserva.fecha}).subscribe(data => {
                if (!data || data.error) {
                  swal('Error', 'Error al cargar datos sesiones', 'error');return;
                }
                // console.log(data);
                if(data[1].length > 0){
                  // console.log(data[1]);
                  that.sesiones = data[1];
                  that.precioAdulto = data[1][0].precio_adultos;
                  that.precioNinio = data[1][0].precio_ninios;

                }else{
                  // console.log(data[0]);
                  that.sesiones = data[0];
                  that.precioAdulto = that.evento.precio;
                  that.precioNinio = that.evento.precio_ninios;
                }
              });
            }
          });
          */
        });
        break;
      }
      case 'canjear':{
        this.reservar = false;
        this.regalar = false;
        this.canjear = true;
        $('#calendar').show();
        $('#btn-reservar').removeClass('selected');
        $('#btn-regalar').removeClass('selected');
        $('#btn-canjear').addClass('selected');
        $('#btn-reservar-movil').removeClass('selected');
        $('#btn-regalar-movil').removeClass('selected');
        $('#custom-mas-adulto').addClass('disabled-custom-input');
        $('#custom-mas-ninio').addClass('disabled-custom-input');
        $('#custom-menos-adulto').addClass('disabled-custom-input');
        $('#custom-menos-ninio').addClass('disabled-custom-input');
        break;
      }
      default:{
        break;
      }
    }
    this.appref.tick();
  }
  SiguienteModalRegalar(){
    if(this.dataReserva.id_evento == ''){
      swal(LG('¡Atención!'), LG('Debe seleccionar una actividad'), 'warning');
      return;
    }
    if(this.dataReserva.adultos == 0){
      swal(LG('¡Atención!'), 'Los niños menores deben de ir acompañados al menos de un adulto', 'warning');
      return;
    }
    $('#content-paso-1').hide();
    $('#content-paso-2').fadeIn();
    window.location.hash = '#content-paso-2';
    this.IframePostMessages();
  }
  SetLocalidadRegalo(){
    this.globals.Api('/localidad', {codpostal: this.dataPersonaRegala.codpostal}).subscribe(data => {
      if (!data || data.error) {
        // swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.dataPersonaRegala.localidad = data.localidad;
      this.dataPersonaRegala.provincia = data.provincia;
    }, error => {
      // toastr.error(`Al bloquear sesión`, 'Error');
    });
  }
  ComprobarGuardarBono(){
    if (
      this.dataReserva.adulto == 0 || this.dataReserva.precio == 0 ||
      this.dataReserva.nombre == '' || this.dataReserva.apellidos == '' || this.dataReserva.email == '' || this.dataReserva.telefono == ''||
      this.dataPersonaRegala.nombre == '' || this.dataPersonaRegala.apellidos == '' || this.dataPersonaRegala.email == '' || this.dataPersonaRegala.telefono == '' ||
      !this.dataPersonaRegala.codpostal || this.dataPersonaRegala.codpostal == '' || !this.dataPersonaRegala.localidad || this.dataPersonaRegala.localidad == ''
      ) {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Revisa los datos'), 'error');
      return;
    }
    if(!this.globals.ValidateEmail(this.dataReserva.email)){
      swal('Error', 'Email incorrecto', 'error');return;
    }
    if(!this.globals.ValidateEmail(this.dataPersonaRegala.email)){
      swal('Error', 'Email incorrecto', 'error');return;
    }
    if (
      !$('#check-politicas-privacidad').is(':checked')
    ){
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Política de privacidad', 'Debe marcar que ha leído y acepta la política de privacidad para continuar', 'error');
      return;
    }
    $.buttonloading('btn-guardar-reserva', 'Realizando pago ...');
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5,
      text: 'Realizando pago ...'
    });
    this.dataReserva.cli_tipo_pago = $('input[type="radio"][name="pago"]:checked').val();
    this.dataReserva.cli_tipo_pago_slug = $('input[type="radio"][name="pago"]:checked').attr('id');
    if(this.dataReserva.cli_tipo_pago == undefined){
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', 'Selecciona un tipo de pago', 'warning');return;
    }
    this.GuardarBono();
  }
  GuardarBono() {
    this.dataReserva.cli_tipo_pago = $('input[type="radio"][name="pago"]:checked').val();
    this.dataReserva.cli_tipo_pago_slug = $('input[type="radio"][name="pago"]:checked').attr('id');
    this.dataReserva.pagos = [{
      cli_tipo_pago: this.dataReserva.cli_tipo_pago,
      cli_tipo_pago_slug: this.dataReserva.cli_tipo_pago_slug
    }];
    if (this.dataReserva.cupon) {
      this.dataReserva.precio = this.dataReserva.total;
    }

    // if (gtag) {
    //   gtag('event', 'conversion', {'send_to': 'AW-10882867819/poZJCL_e-LUDEOvErcUo',
    //     'value': this.dataReserva.precio,
    //     'currency': 'EUR'
    //   });
    // }
    this.dataReserva.id_pago = 1;
    this.dataReserva.id_estado_bono = 1;
    if (this.forma_pago_seleccionada && this.forma_pago_seleccionada.slug == 'stripe' && this.stripe) {
      let that = this;
      this.stripe.createToken(this.stripe_card).then(function(result) {
        if (result.error) {
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          $.buttoncancelloading('btn-guardar-reserva');
        } else {
          that.dataReserva.stripe_token = result.token.id;
          that.globals.Api('/pub-stripe-pago-bono', {data: that.dataReserva, data_regala: that.dataPersonaRegala}).subscribe(data => {
            if (!data || !data.value) {
              $.LoadingOverlay("hide", true);
              $.buttoncancelloading('btn-guardar-reserva');
              swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');return;
            }
            setTimeout(() => {
              $.LoadingOverlay("hide", true);
              $.buttoncancelloading('btn-guardar-reserva');
              that.dataReserva.stripe_order = data.value;
              that.dataReserva.id_pago = 3;
              that.dataReserva.id_estado_bono = 2;
              that.EnviarDatosBono();
            }, 200);
          }, error => {
            $.LoadingOverlay("hide", true);
            $.buttoncancelloading('btn-guardar-reserva');
            swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');
          });
        }
      });
      return;
    }
    this.EnviarDatosBono();
  }

  EnviarDatosBono() {
    $.LoadingOverlay("hide", true);
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5,
      text: 'Enviando datos ...'
    });
    $.buttonloading('btn-guardar-reserva', 'Enviando datos ...');
    this.dataReserva.codigo = this.dataReserva.id_evento +''+this.dataReserva.email.substring(0,2).toUpperCase()+''+this.getRandomInt(10000,99999)+''+this.dataPersonaRegala.email.substring(0,2).toUpperCase();
    this.globals.Api('/eve-bonos-calendar-add',{datos: this.dataReserva, datos_persona_regala: this.dataPersonaRegala, id_actividad: this.dataReserva.id_evento}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al hacer la reserva', 'error');
        $('#content-paso-2').hide();
        $('#content-paso-error').fadeIn();
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('btn-guardar-reserva');
        return;
      }
      window.parent.postMessage('btn-guardar-reserva', '*');
      if (data.form && data.form != '') {
        $('body').append(data.form);
        setTimeout(function() {
            if ($('#paypal-form').length) {
              $('#paypal-form').submit();
              $('#paypal-form').remove();
              let IntervalCompReserva1 = setInterval(()=>{
                this.globals.Api('/pub-get-reserva',{id: data.value}).suscribe(data => {
                  if (!data || data.error) {
                    return;
                  }
                  if(data.id_estado == 3){
                    clearInterval(IntervalCompReserva1);
                    window.parent.postMessage({event_id: 'reserva-confirmada', reserva: this.data}, '*');
                  }
                });
              },3000);
            }
            if ($('#redsys-form').length) {
              $('#redsys-form').submit();
              $('#redsys-form').remove();
              $.LoadingOverlay("hide", true);
              $('#content-paso-2').hide();
              $('#content-paso-3').fadeIn();
              let IntervalCompReserva2 = setInterval(()=>{
                this.globals.Api('/pub-get-reserva',{id: data.value}).suscribe(data => {
                  if (!data || data.error) {
                    return;
                  }
                  if(data.id_estado == 3){
                    clearInterval(IntervalCompReserva2);
                    window.parent.postMessage({event_id: 'reserva-confirmada', reserva: this.data}, '*');
                  }
                });
              },3000);
            }
        }, 100);
      } else {
        $.LoadingOverlay("hide", true);
        // swal('¡Reserva realizada con éxito!', 'Recibirá un correo a la dirección facilitada con la confirmación de la reserva.', 'success');
        $('#content-paso-2').hide();
        $('#content-paso-3').fadeIn();
        this.IframePostMessages();
        if (this.forma_pago_seleccionada && this.forma_pago_seleccionada.slug == 'stripe' && this.dataReserva.stripe_order) {
          this.globals.Api('/pub-get-reserva-bono', {stripe_order: this.dataReserva.stripe_order}).subscribe(data => {
            if (!data || !data.id) return;
            this.dataReserva = data;
            this.dataReserva.CompraBono = true;
            this.appref.tick();
          });
        }
      }
    }, error => {
      $.LoadingOverlay("hide", true);
      $.buttoncancelloading('btn-guardar-reserva');
    });
  }
  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  //Canjear
  InputCodigoCanjear() {
    this.dataCanjearBono.selsesion = null;
    this.dataCanjearBono.bono = null;
    if (!this.dataCanjearBono.codigo || this.dataCanjearBono.codigo == '' || this.dataCanjearBono.codigo.length < 4) {
      return;
    }
    clearTimeout($.IDTimeInputCodigo);
    $.IDTimeInputCodigo = setTimeout(() => {
      this.globals.Api('/pub-check-bono-regalo', {codigo: this.dataCanjearBono.codigo}).subscribe((data:any) => {
        if (!data) return;
        this.dataCanjearBono.bono = data;
        this.dataCanjearBono.selsesion = true;
        this.evento.id = data.id_actividad;
        this.dataCanjearBono.fecha = moment().format('YYYY-MM-DD');
        this.CargarSesionesCanjear();
        this.appref.tick();
      });
    }, 400);
  }
  CargarSesionesCanjear() {
    let params = {
      fecha: moment(this.dataCanjearBono.fecha),
      fecha_format: moment(this.dataCanjearBono.fecha).format('DD/MM/YYYY'),
      fecha_format_en: moment(this.dataCanjearBono.fecha).format('YYYY-MM-DD')
    };
    this.dataCanjearBono.mensajesesiones = null;
    $('#custom-menos-adulto').addClass('disabled-custom-input');
    $('#custom-menos-ninio').addClass('disabled-custom-input');
    this.globals.Api('/eve-eventos',{id: this.dataCanjearBono.bono.id_actividad}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar datos evento', 'error');return;
      }
      this.evento = data[0];
      if (this.evento.vehiculo) {
        // Cálculo disponibilidad condicionante vehículos
        this.sesiones = null;
        this.globals.Api('/eve-eventos-sesiones-vehiculos-v2',{id: this.evento.id, fecha: params.fecha_format_en}).subscribe(data => {
          if (!data || data.error) {
            swal('Error', 'Error al cargar datos sesiones', 'error');return;
          }
          if(data[1].length > 0){
            // console.log(data[1]);
            this.sesiones = data[1];
          }else{
            // console.log(data[0]);
            this.sesiones = data[0];
          }
          var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
          var d = new Date(params.fecha_format_en);
          var dayName = days[d.getDay()];
          this.globals.Api('/check-dia-disponible-v2',{id: this.evento.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
            if (!data || data.error) {
              swal('Error', 'Error al cargar datos sesiones', 'error');return;
            }
            if(data[0].count == 0 && data[0].dias_especial_disponible == 0){
              this.sesiones = null;
            }else{
              // console.log(this.sesiones);
              for(let i = 0; i <= this.sesiones.length; i++){
                // console.log(this.sesiones[i].sesion);
                let sesion = this.sesiones[i].sesion;
                // console.log(sesion);
                this.globals.Api('/eve-eventos-check-vehiculos-v2',{id: this.evento.id, fecha: params.fecha_format_en, sesion: sesion}).subscribe(data => {
                  if (!data || data.error) {
                    swal('Error', 'Error al verificar vehículos', 'error');return;
                  }
                  // console.log(data);
                  let plazasDisponibles = 0;
                  let plazasDisponiblesSinCoche = this.sesiones[i].aforo + this.sesiones[i].plazas_adicionales;
                  this.sesiones[i].aforo_restante = this.sesiones[i].aforo_restante + this.sesiones[i].plazas_adicionales;
                  data.forEach(vehiculo => {
                    plazasDisponibles += vehiculo.plazas_disponibles;
                  });
                  // console.log(plazasDisponibles);
                  if(this.sesiones[i].aforo_restante > plazasDisponibles){
                    this.sesiones[i].aforo_restante = plazasDisponibles;
                  }
                  if(plazasDisponibles > plazasDisponiblesSinCoche){
                    this.sesiones[i].aforo_restante = plazasDisponiblesSinCoche;
                  }
                });
              }
            }
          });
        });
      }else{
        // Cálculo disponibilidad normal sin vehículos
        this.sesiones = null;
        this.globals.Api('/pub-eve-eventos-sesiones-v2',{id: this.evento.id, fecha: params.fecha_format_en}).subscribe(data => {
          if (!data || data.error) {
            swal('Error', 'Error al cargar datos sesiones', 'error');return;
          }
          // console.log(data);
          if(data[1].length > 0){
            // console.log(data[1]);
            this.sesiones = data[1];

          }else{
            // console.log(data[0]);
            this.sesiones = data[0];
          }
          var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
          var d = new Date(params.fecha_format_en);
          var dayName = days[d.getDay()];
          this.globals.Api('/pub-check-dia-disponible-v2',{id: this.evento.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
            if (!data || data.error) {
              swal('Error', 'Error al cargar datos sesiones', 'error');return;
            }
            if(data[0].count == 0 && data[0].dias_especial_disponible == 0){
              this.sesiones = null;
            }else{
              for(let i = 0; i <= this.sesiones.length; i++){
                this.sesiones[i].aforo_restante = this.sesiones[i].aforo_restante + this.sesiones[i].plazas_adicionales;
                if(this.sesiones[i].aforo_restante < 0) this.sesiones[i].aforo_restante = 0;
              }
            }
          });
        });
      }
    });
  }
  SiguienteModalCanjear() {
    let plazasBono:number = this.dataCanjearBono.bono.adultos + this.dataCanjearBono.bono.ninios;
    if (this.plazasDisponibles < plazasBono) return;
    this.dataReserva.id_evento = this.dataCanjearBono.bono.id_actividad;
    this.dataReserva.fecha = this.dataCanjearBono.fecha;
    this.dataReserva.sesion = this.dataCanjearBono.sesion;
    this.dataReserva.adultos = this.dataCanjearBono.bono.adultos;
    this.dataReserva.ninios = this.dataCanjearBono.bono.ninios;
    this.dataReserva.precio = this.dataCanjearBono.bono.total;
    this.dataReserva.total = this.dataCanjearBono.bono.total;
    this.dataReserva.nombre = this.dataCanjearBono.bono.nombre;
    this.dataReserva.apellidos = this.dataCanjearBono.bono.apellidos;
    this.dataReserva.cli_codpostal = this.dataCanjearBono.bono.codigo_postal;
    this.dataReserva.cli_localidad = this.dataCanjearBono.bono.localidad;
    this.dataReserva.cli_provincia = this.dataCanjearBono.bono.provincia;
    this.dataReserva.telefono = this.dataCanjearBono.bono.telefono;
    this.dataReserva.email = this.dataCanjearBono.bono.email;
    this.dataReserva.bono = this.dataCanjearBono.bono;
    $('#content-paso-1').hide();
    $('#content-paso-2').fadeIn();
    window.location.hash = '#content-paso-2';
    this.IframePostMessages();
  }
  CanjearBono() {
    if (
      this.dataReserva.fecha == null || this.dataReserva.fecha == '' || this.dataReserva.fecha == '0000-00-00' ||
      this.dataReserva.sesion == null || this.dataReserva.sesion == '' ||
      this.dataReserva.adulto == 0 || this.dataReserva.precio == 0 ||
      this.dataReserva.nombre == '' || this.dataReserva.apellidos == '' || this.dataReserva.email == '' || this.dataReserva.telefono == ''
      ) {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Revisa los datos'), 'error');
      return;
    }
    if (!this.globals.ValidateEmail(this.dataReserva.email)) {
      swal('Error', 'Email incorrecto', 'error');return;
    }
    if (
      !$('#check-politicas-privacidad').is(':checked')
    ) {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Política de privacidad', 'Debe marcar que ha leído y acepta la política de privacidad para continuar', 'error');
      return;
    }
    this.dataReserva.id_estado_bono = 3;
    this.dataReserva.cli_tipo_pago = 'Bono canjeado';
    this.dataReserva.cli_tipo_pago_slug = 'bono';
    this.dataReserva.pagos = [{
      cli_tipo_pago: this.dataReserva.cli_tipo_pago,
      cli_tipo_pago_slug: this.dataReserva.cli_tipo_pago_slug
    }];
    this.EnviarDatosReserva();
  }
}
