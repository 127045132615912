<div *ngIf="web == 0">
    <h2>Esta actividad no está disponible para reservar</h2>
</div>
<div id="content-iframe-frontend" *ngIf="web == 1">
    <!-- <div class="modal inmodal fade" id="modal-reservar-calendar" tabindex="-1" role="dialog"> -->
    <div id="modal-reservar-calendar">
        <div class="modal-dialog">
            <div class="modal-content modal-lg">
            <div class="modal-header hide">
                <button type="button" class="close" data-dismiss="modal" style="font-size: 35px;"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
                <h3 class="modal-title" style="text-align: center;">Añadir reserva</h3>
            </div>
            <div>
              <div id="content-paso-1" class="row modal-body">
                <div id="content-buttons-reserva-regalo-movil" class="col-lg-12 col-sm-12 col-xs-12 margin-bottom-10 hide-tablet-desktop" style="margin-top: 20px;">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-xs-6">
                            <button id="btn-reservar-movil" class="selected btn-reserva-regalo" (click)="setReservaRegaloCanjear('reservar')"><i class="fa fa-ticket"></i> Reservar</button>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-xs-6">
                            <button id="btn-regalar-movil" class="btn-reserva-regalo" (click)="setReservaRegaloCanjear('regalar')"><i class="fa fa-gift"></i> Regalar</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-xs-12">
                    <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop label-calendar-title hide" ><i class="fa fa-calendar"></i> {{LG('Selecciona fecha')}}</label>
                    <hr class="separador-general hide-tablet-desktop custom-form-reservar hide">
                    <div class="row display-flex width-fit-auto hide-movil">
                        <!-- <div class="col-lg-3"><h3 class="title-paso">1. Fecha y entradas</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">2. Tus datos</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">3. Reserva completada</h3></div> -->
                        <h3 class="title-paso active">1. {{LG('Fecha y plazas')}}</h3>
                        <h3 class="title-paso">2. {{LG('Tus datos')}}</h3>
                        <h3 class="title-paso">3. {{LG('Reserva completada')}}</h3>
                    </div>
                    <div id="content-calendar-data" class="row">
                        <div class="col-lg-5 col-sm-5 col-xs-12">
                            <div id="calendar" class="calendario calendario-dashboard calendario-front"></div>
                            <div id="content-legend" style="display: block; margin-top: 10px;"  *ngIf="reservar || canjear">
                                <div class="legend" style="width: 90px; display: flex; float: left; margin-bottom: 5px;">
                                    <div style="width: 10px; background-color: #5da84e; border-radius: 50px; height: 10px;border: solid 1px white;"></div>
                                    <div class="text-legend" style="width: 85%; margin-left: 5%;color: black;line-height: 10px;">Disponibles</div>
                                </div>
                                <div class="legend" style="width: 140px; display: flex; float: left; margin-bottom: 5px;">
                                    <div style="width: 10px; background-color: #e5b437; border-radius: 50px; height: 10px;border: solid 1px white;"></div>
                                    <div class="text-legend" style="width: 85%; margin-left: 5%;color: black;line-height: 10px;">Poca disponibilidad</div>
                                </div>
                                <div class="legend" style="width: 80px; display: flex; float: left; margin-bottom: 5px;">
                                    <div style="width: 10px; background-color: #a30404; border-radius: 50px; height: 10px;border: solid 1px white;"></div>
                                    <div class="text-legend" style="width: 85%; margin-left: 5%;color: black;line-height: 10px;">Agotadas</div>
                                </div>
                                <div class="legend" style="width: 100px; display: flex; float: left; margin-bottom: 5px;">
                                    <div style="width: 10px; background-color: black; border-radius: 50px; height: 10px;border: solid 1px white;"></div>
                                    <div class="text-legend" style="width: 85%; margin-left: 5%;color: black;line-height: 10px;">No disponible</div>
                                </div>
                            </div>
                            <div id="content-imagen-regalo" class="row" *ngIf="regalar">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div id="background-ticket-reservar">
                                        <img src="assets/ticket-bono-formulario.svg" alt="">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <p id="text-descripcion-regalo">Para regalar una actividad, no hace falta elegir una fecha, la fecha de la actividad se podrá elegir una vez se canjea el bono regalo</p>
                                </div>
                            </div>
                        </div>
                        <div id="content-entradas" class="col-lg-7 col-sm-7 col-xs-12">
                            <div class="form-group row">
                                <div id="content-buttons-reserva-regalo" class="col-lg-12 col-sm-12 col-xs-12 margin-bottom-10">
                                    <div class="row">
                                        <div class="col-lg-4 col-sm-4 col-xs-4 hide-movil">
                                            <button id="btn-reservar" class="selected btn-reserva-regalo" (click)="setReservaRegaloCanjear('reservar')"><i class="fa fa-ticket"></i> Reservar</button>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-xs-3 hide-movil">
                                            <button id="btn-regalar" class="btn-reserva-regalo" (click)="setReservaRegaloCanjear('regalar')"><i class="fa fa-gift"></i> Regalar</button>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-xs-12">
                                            <button id="btn-canjear" class="btn-reserva-regalo" (click)="setReservaRegaloCanjear('canjear')"><i class="fa fa-retweet"></i> Canjear bono</button>
                                        </div>
                                    </div>
                                </div>
                                <div id="content-date-reserva" class="display-flex hide">
                                    <label class="col-lg-4 col-form-label label-modal-title"><i class="icofont-calendar"></i> Fecha seleccionada</label>
                                    <div class="col-lg-8">
                                        <input id="date-reservar" type="date" class="form-control" maxlength="5" style="width: fit-content;" disabled>
                                    </div>
                                </div>
                                <div class="margin-top-10 display-flex col-lg-12 col-sm-12 col-xs-12" id="content-radio-reserva"
                                *ngIf="reservar || dataCanjearBono.selsesion" style="flex-wrap: wrap;">
                                    <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop" ><i class="icofont-clock-time"></i> Elige una sesión</label>
                                    <label class="col-lg-4 col-form-label label-modal-title hide-movil" ><i class="icofont-clock-time"></i> {{LG('Sesiones')}}</label>
                                    <hr class="separador-general hide-tablet-desktop custom-form-reservar" style="margin-left: 16px !important;">
                                    <ng-container *ngIf="sesiones.length == 0">
                                        <div class="content-radio-sesion display-flex aviso-sesiones">
                                            {{LG('*Seleccione un día para ver las sesiones')}}
                                        </div>
                                    </ng-container>
                                    <div class="content-radio-sesion display-flex" *ngFor="let sesion of sesiones">
                                        <input id="{{sesion.sesion}}" type="radio" class="form-control radios-sesion" value="{{sesion.sesion}}-{{sesion.aforo}}" (click)="setSesionCheck(sesion.sesion,sesion.aforo_restante)" name="sesion" style="width: fit-content; height: fit-content; margin-right: 10px;"><label for="{{sesion.sesion}}">{{sesion.sesion}}h</label>
                                    </div>
                                    <div *ngIf="dataCanjearBono && dataCanjearBono.mensajesesiones" class="content-radio-sesion aviso-sesiones" style="color: red; font-weight: 700;">
                                        {{dataCanjearBono.mensajesesiones}}
                                    </div>
                                </div>
                                <div id="content-canjear" class="col-lg-12 col-sm-12 col-xs-12" *ngIf="canjear">
                                    <div class="form-group display-flex m-t-10" style="align-items:center;">
                                        <label style="flex-shrink: 0;margin:0;margin-right:20px;"><i class="fa fa fa-ticket"></i> {{LG('Introduce tu código')}}*</label>
                                        <input type="text" class="form-control inline-block" placeholder="{{LG('Introduce tu código')}}"
                                            [(ngModel)]="dataCanjearBono.codigo"
                                            maxlength="20"
                                            (input)="InputCodigoCanjear()"
                                            >
                                    </div>
                                    <div class="m-t-10" *ngIf="dataCanjearBono.codigo && dataCanjearBono.codigo.length > 0 && !dataCanjearBono.bono">
                                        {{LG('Código no válido')}}
                                    </div>
                                </div>
                                <div class="margin-top-10 col-lg-12 col-sm-12 col-xs-12" id="content-radio-personas" *ngIf="reservar || regalar">
                                    <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop hide" ><i class="fa fa-ticket"></i> Entradas</label>
                                    <hr class="separador-general hide-tablet-desktop custom-form-reservar hide">
                                    <div class="col-lg-12 margin-bottom-10">
                                        <div class="row">
                                            <ng-container *ngIf="globals.api_url == globals.entornos.jalama">
                                                <div class="col-lg-12 col-md-12 col-xs-12">
                                                    <p style="font-weight: 700;">*Para grupos de menos de 4 o más de 6 personas contactar en este <a href="https://wa.me/34667027319" target="_blank" style="text-decoration: underline;">enlace</a></p>
                                                </div>
                                            </ng-container>
                                            <div class="col-lg-7 col-xs-6">
                                                <label class="col-form-label label-modal-title" ><i class="icofont-user-alt-1"></i> {{LG('Adultos')}} <span class="precio-persona">{{precioAdulto}} €</span></label><br>

                                            </div>
                                            <div class="col-lg-3 col-xs-4 padding-left-0-movil">
                                                <div class="display-flex">
                                                    <i id="custom-menos-adulto" class="icofont-minus-circle custom-menos disabled-custom-input"></i><input id="personas-adulto" class="form-control custom-input-number" type="text" maxlength="2" [(ngModel)]="dataReserva.adultos" disabled><i id="custom-mas-adulto" class="icofont-plus-circle custom-mas"></i>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-xs-2">
                                                <p id="precio-calulo-adulto" class="precio-calculo">{{precioCalculoAdulto}} €</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 margin-bottom-10" *ngIf="precioNinio">
                                        <div class="row">
                                            <div class="col-lg-7 col-xs-6">
                                                <label class="col-form-label label-modal-title" ><i class="icofont-user-alt-1"></i> {{LG('Niños')}} <span class="precio-persona">{{precioNinio}} €</span><br/><span> ({{LG('hasta')}} {{evento.edad_ninios}} {{LG('años')}}) </span></label><br>

                                            </div>
                                            <div class="col-lg-3 col-xs-4 padding-left-0-movil">
                                                <div class="display-flex">
                                                    <i id="custom-menos-ninio" class="icofont-minus-circle custom-menos disabled-custom-input"></i><input id="personas-ninios" class="form-control custom-input-number" type="text" maxlength="2" [(ngModel)]="dataReserva.ninios" disabled><i id="custom-mas-ninio" class="icofont-plus-circle custom-mas"></i>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-xs-2">
                                                <p id="precio-calcul-ninio" class="precio-calculo">{{precioCalculoNinio}} €</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 margin-bottom-10 margin-bottom-0-movil hide">
                                        <div class="row">
                                            <div class="col-lg-7 col-xs-6">
                                                <label class="col-form-label label-modal-title" ><i class="icofont-user-alt-1"></i> {{LG('Niños gratis')}} <br/><span>{{LG('(menores de 3 años)')}} </span> </label><br>

                                            </div>
                                            <div class="col-lg-3 col-xs-3 padding-left-0-movil">
                                                <div class="display-flex">
                                                    <i id="custom-menos-ninio-gratis" class="icofont-minus-circle custom-menos disabled-custom-input"></i><input id="personas-ninios-gratis" class="form-control custom-input-number" type="text" maxlength="2" [(ngModel)]="dataReserva.niniosGratis" disabled><i id="custom-mas-ninio-gratis" class="icofont-plus-circle custom-mas"></i>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-xs-3">
                                                <p class="precio-calculo">0 €</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 margin-bottom-10">
                                        <div class="row">
                                            <div class="col-lg-2 col-xs-2">
                                            </div>
                                            <div class="col-lg-2 col-xs-2">
                                            </div>
                                            <div class="col-lg-8 col-xs-8">
                                                <p class="precio-calculo-total">{{LG('Total')}}: {{dataReserva.precio}} €</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="content-canjear-resumen" class="col-lg-12 col-sm-12 col-xs-12" *ngIf="canjear">
                                    <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                                    <ul class="list-group" *ngIf="dataCanjearBono.bono">
                                        <li class="list-group-item">
                                            <span class="inline-block" style="width:100px;">{{LG('Actividad')}}: </span>
                                            <b>{{dataCanjearBono.bono.actividad}}</b>
                                        </li>
                                        <li class="list-group-item">
                                            <span class="inline-block" style="width:100px;">{{LG('Adultos')}}: </span>
                                            <b>{{dataCanjearBono.bono.adultos}}</b>
                                        </li>
                                        <li class="list-group-item">
                                            <span class="inline-block" style="width:100px;">{{LG('Niños')}}: </span>
                                            <b>{{dataCanjearBono.bono.ninios}}</b>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer col-md-12 col-xs-12 padding-55-70 padding-top-0">
                    <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
                    <button type="button" class="btn btn-primary" id="btn-siguiente" *ngIf="dataCanjearBono && dataCanjearBono.puedepaso2  && canjear" (click)="SiguienteModalCanjear()">{{LG('Siguiente')}}</button>
                    <button type="button" class="btn btn-primary" id="btn-siguiente" *ngIf="dataReserva.precio > 0 && reservar" (click)="SiguienteModal()">{{LG('Reservar')}}</button>
                    <button type="button" class="btn btn-primary" id="btn-siguiente" *ngIf="dataReserva.precio > 0 && regalar" (click)="SiguienteModalRegalar()">{{LG('Siguiente')}}</button>
                </div>
              </div>
              <div id="content-paso-2" class="row modal-body" style="display: none;">
                <div class="col-md-12 col-xs-12">
                    <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop label-calendar-title" *ngIf="reservar || canjear"><i class="fa fa-ticket"></i> {{LG('Resumen de tus entradas')}}</label>
                    <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop label-calendar-title" *ngIf="regalar"><i class="fa fa-ticket"></i> Resumen de las entradas</label>
                    <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                    <div class="row display-flex width-fit-auto hide-movil">
                        <!-- <div class="col-lg-3"><h3 class="title-paso">1. Fecha y entradas</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">2. Tus datos</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">3. Reserva completada</h3></div> -->
                        <h3 class="title-paso anterior">1. {{LG('Fecha y entradas')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                        <h3 class="title-paso active">2. {{LG('Tus datos')}}</h3>
                        <h3 class="title-paso">3. {{LG('Reserva completada')}}</h3>
                    </div>
                    <div id="content-resumen-form" class="row">
                        <div id="content-resumen" class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                            <h4 class="hide-movil">{{LG('Resumen')}}</h4>
                            <div class="row">
                                <div class="col-lg-12 col-xs-12">
                                    <p class="no-margin-bottom">{{LG('Fecha')}}: <span>{{dataReserva.fecha}}</span></p>
                                </div>
                                <div class="col-lg-12 col-xs-12">
                                    <p class="no-margin-bottom">{{LG('Sesión')}}: <span>{{dataReserva.sesion}}</span></p>
                                </div>
                                <div class="col-lg-12 col-xs-12">
                                    <p class="no-margin-bottom">{{LG('Adultos')}}: <span>{{dataReserva.adultos}}</span></p>
                                </div>
                                <div class="col-lg-12 col-xs-12" *ngIf="precioNinio">
                                    <p class="no-margin-bottom">{{LG('Niños')}} <span style="font-size: 12px;">{{LG('hasta')}} {{evento.edad_ninios}} {{LG('años')}}</span>: <span>{{dataReserva.ninios}}</span></p>
                                </div>
                                <div class="col-lg-12 col-xs-12 hide">
                                    <p class="no-margin-bottom">{{LG('Niños')}} <span style="font-size: 12px;">{{LG('menores de 3 años')}}</span>: <span>{{dataReserva.niniosGratis}}</span></p>
                                </div>
                                <ng-container *ngIf="!dataReserva.cupon">
                                    <div class="col-lg-12 col-xs-12">
                                        <p class="no-margin-bottom">{{LG('Precio')}}: <span>{{dataReserva.precio}}€</span></p>
                                        <p class="no-margin-bottom" *ngIf="anticipo.porcentaje_anticipo != 100 && reservar">*Al hacer la reserva, usted abona el {{anticipo.porcentaje_anticipo}}%. El resto se abona el día de la actividad</p>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="dataReserva.cupon">
                                    <div class="col-lg-12 col-xs-12">
                                        <p class="no-margin-bottom">{{LG('Precio')}}: <span>{{dataReserva.precio_original}}€</span></p>
                                    </div>
                                    <div class="col-lg-12 col-xs-12">
                                        <p class="no-margin-bottom">{{LG('Descuento')}}: -<span>{{dataReserva.cupon.descuento}}</span></p>
                                        <p class="no-margin-bottom">{{LG('Total')}}: <span>{{globals.FloatES(dataReserva.total)}}€</span></p>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div id="content-datos" class="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                            <h4 class="hide-movil hide">{{LG('Datos_cliente')}}</h4>
                            <h4 class="hide-tablet-desktop" style="margin-bottom: 5px;" *ngIf="reservar || canjear"><i class="fa fa-pencil"></i> {{LG('Datos_rellena_cliente')}}</h4>
                            <h4 style="margin-bottom: 5px;" *ngIf="regalar"><i class="fa fa-pencil"></i> Rellena los datos del destinatario</h4>
                            <hr class="separador-general hide-tablet-desktop custom-form-reservar" style="margin-left: 0px !important;">
                            <div class="form-group row m-b-5">
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label class="hide">{{LG('Nombre')}}*</label>
                                    <input type="text" placeholder="{{LG('Nombre')}}" [(ngModel)]="dataReserva.nombre">
                                </div>
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label class="hide">{{LG('Apellidos')}}*</label>
                                    <input type="text" placeholder="{{LG('Apellidos')}}" [(ngModel)]="dataReserva.apellidos">
                                </div>
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label class="hide">{{LG('Teléfono')}}*</label>
                                    <input type="tel" placeholder="{{LG('Teléfono')}}" [(ngModel)]="dataReserva.telefono">
                                </div>
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label class="hide">{{LG('Email')}}*</label>
                                    <input type="mail" placeholder="{{LG('Email')}}" [(ngModel)]="dataReserva.email">
                                </div>
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label class="hide">Código postal</label>
                                    <input type="number" placeholder="Código postal" [(ngModel)]="dataReserva.cli_codpostal" (change)="SetLocalidad()">
                                </div>
                                <div class="col-lg-6 col-xs-6 margin-bottom-10 input-cupon">
                                    <label class="hide">{{LG('Cupón de descuento')}}</label>
                                    <input type="text" placeholder="{{LG('Cupón de descuento')}}" [(ngModel)]="dataReserva.codigo_cupon" (input)="ComprobarCupon()"
                                        [disabled]="dataReserva.email && dataReserva.email.trim() != '' ? false : true">
                                    <i class="fa fa-check text-success icon-cupon" *ngIf="dataReserva.mostracuponok"></i>
                                    <i class="fa fa-times text-danger icon-cupon" *ngIf="dataReserva.mostracuponko"></i>
                                </div>
                                <div class="col-lg-12 col-xs-12 margin-bottom-10">
                                    <label class="hide">Notas</label>
                                    <textarea placeholder="Notas o comentarios" [(ngModel)]="dataReserva.cli_notas" style="width: 100%;"></textarea>
                                </div>
                                <!-- <div class="col-lg-3 col-xs-3 margin-bottom-10 margin-top-26">
                                    <input id="pago-tarjeta" class="fit-content" type="radio" name="pago"><label style="margin-left: 10px;" for="pago-tarjeta">Tarjeta</label>
                                </div>
                                <div class="col-lg-3 col-xs-3 margin-bottom-10 margin-top-26">
                                    <input id="pago-paypal" class="fit-content" type="radio" name="pago"><label style="margin-left: 10px;" for="pago-paypal">Paypal</label>
                                </div> -->
                            </div>
                            <h4 style="margin-bottom: 5px;" *ngIf="regalar"><i class="fa fa-pencil"></i> Rellena tus datos</h4>
                            <hr class="separador-general hide-tablet-desktop custom-form-reservar" style="margin-left: 0px !important;" *ngIf="regalar">
                            <div class="form-group row m-b-5" *ngIf="regalar">
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label class="hide">{{LG('Nombre')}}*</label>
                                    <input type="text" placeholder="{{LG('Nombre')}}" [(ngModel)]="dataPersonaRegala.nombre">
                                </div>
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label class="hide">{{LG('Apellidos')}}*</label>
                                    <input type="text" placeholder="{{LG('Apellidos')}}" [(ngModel)]="dataPersonaRegala.apellidos">
                                </div>
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label class="hide">{{LG('Teléfono')}}*</label>
                                    <input type="tel" placeholder="{{LG('Teléfono')}}" [(ngModel)]="dataPersonaRegala.telefono">
                                </div>
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label class="hide">{{LG('Email')}}*</label>
                                    <input type="mail" placeholder="{{LG('Email')}}" [(ngModel)]="dataPersonaRegala.email">
                                </div>
                                <div class="col-lg-6 col-xs-6 margin-bottom-10">
                                    <label class="hide">Código postal</label>
                                    <input type="number" placeholder="Código postal" [(ngModel)]="dataPersonaRegala.codpostal" (change)="SetLocalidadRegalo()">
                                </div>
                                <!-- <div class="col-lg-3 col-xs-3 margin-bottom-10 margin-top-26">
                                    <input id="pago-tarjeta" class="fit-content" type="radio" name="pago"><label style="margin-left: 10px;" for="pago-tarjeta">Tarjeta</label>
                                </div>
                                <div class="col-lg-3 col-xs-3 margin-bottom-10 margin-top-26">
                                    <input id="pago-paypal" class="fit-content" type="radio" name="pago"><label style="margin-left: 10px;" for="pago-paypal">Paypal</label>
                                </div> -->
                            </div>
                            <div class="clearfix"></div>
                            <div class="" *ngIf="!canjear">
                                <label class="col-form-label label-modal-title hide-tablet-desktop label-calendar-title" style="margin-top: 0px;"><i class="icofont-credit-card"></i> Tipos de pago</label>
                                <hr class="separador-general hide-tablet-desktop custom-form-reservar" style="margin-left: 0px !important;">
                                <div *ngFor="let item of formas_pago" class="inline-block m-r-20 formas-pago">
                                    <input id="{{item.slug}}" class="fit-content" type="radio" name="pago" value="{{item.forma_pago}}" (click)="SetFormaPago(item)">
                                    <label style="margin-left: 10px;" for="{{item.slug}}" (click)="SetFormaPago(item)">{{item.forma_pago}}
                                        <i class="icofont-paypal-alt" *ngIf="item.forma_pago == 'Paypal'"></i>
                                        <i class="icofont-credit-card" *ngIf="item.forma_pago == 'Tarjeta bancaria' || item.forma_pago == 'Tarjeta de crédito'"></i>
                                        <i class="icofont-stripe-alt" *ngIf="item.forma_pago == 'Stripe'"></i>
                                    </label>
                                </div>
                            </div>
                            <div id="stripe-form" class="m-t-10 m-b-10" style="display:none;">
                                <div id="card-element"></div>
                                <div id="card-errors" role="alert"></div>
                            </div>
                            <div id="content-politicas-privacidad" style="display: flex;">
                                <input id="check-politicas-privacidad" type="checkbox" style="width: fit-content; margin-right: 5px;"/><label for="check-pliticas-privacidad">{{LG('Acepto las')}} <a href="{{url_politicas_privacidad.url_politica_cancelacion}}" target="_blank" style="font-weight: 700;">{{LG('política de privacidad')}}</a> y las <a href="{{url_politicas_privacidad.url_politica_cancelacion}}" target="_blank">políticas de cancelación</a></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer col-md-12 col-xs-12 padding-55-70">
                    <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
                    <button type="button" class="btn btn-white" id="btn-anterior" (click)="AnteriorModal()">{{LG('Anterior')}}</button>
                    <button type="button" class="btn btn-primary" id="btn-guardar-reserva" *ngIf="reservar" (click)="ComprobarGuardarReserva()">{{LG('Pagar y finalizar')}}</button>
                    <button type="button" class="btn btn-primary" id="btn-guardar-reserva" *ngIf="regalar" (click)="ComprobarGuardarBono()">{{LG('Pagar y finalizar')}}</button>
                    <button type="button" class="btn btn-primary" id="btn-guardar-reserva" *ngIf="canjear" (click)="CanjearBono()">{{LG('Finalizar')}}</button>
                </div>
              </div>
              <div id="content-paso-3" class="row modal-body" style="display: none;">
                <div class="col-md-12 col-xs-12">
                    <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop label-calendar-title" ><i class="fa fa-ticket"></i> {{LG('Resumen de la reserva')}}</label>
                    <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                    <div class="row display-flex width-fit-auto hide-movil">
                        <!-- <div class="col-lg-3"><h3 class="title-paso">1. Fecha y entradas</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">2. Tus datos</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">3. Reserva completada</h3></div> -->
                        <h3 class="title-paso anterior">1. {{LG('Fecha y entradas')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                        <h3 class="title-paso anterior">2. {{LG('Tus datos')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                        <h3 class="title-paso active">3. {{LG('Reserva completada')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                    </div>
                    <ng-container *ngIf="!forma_pago_seleccionada || forma_pago_seleccionada.slug != 'stripe'">
                        <div id="content-resumen-form" class="row" style="text-align: center;">
                            {{LG('Si ya has terminado puedes volver a realizar otra reserva')}}.
                            <div class="row row-small datos-reserva" *ngIf="dataReserva">
                                <div class="col-xs-6">
                                  <ul class="list-group">
                                      <li class="list-group-item active">{{LG('Tus datos')}}</li>
                                      <li class="list-group-item datos">
                                        <div>{{dataReserva.nombre}} {{dataReserva.apellidos}}</div>
                                        <div *ngIf="dataReserva.cli_empresa">{{dataReserva.empresa}}<span *ngIf="dataReserva.cli_nifcif">, {{dataReserva.cli_nifcif}}</span></div>
                                        <div>{{dataReserva.cli_direccion}},<span *ngIf="dataReserva.cli_codpostal"> {{dataReserva.cli_codpostal}}</span></div>
                                        <div>{{dataReserva.cli_localidad}}<span *ngIf="dataReserva.cli_pais">, {{dataReserva.cli_pais}}</span></div>
                                        <div *ngIf="dataReserva.cli_email"><i class="fa fa-envelope-o"></i> {{dataReserva.email}}</div>
                                        <div *ngIf="dataReserva.cli_telefono"><i class="fa fa-phone"></i> {{dataReserva.telefono}}</div>
                                        <div *ngIf="dataReserva.cli_notas"><br>{{dataReserva.cli_notas}}</div>
                                      </li>
                                  </ul>
                                  <ul class="list-group">
                                      <li class="list-group-item active">{{LG('Modalidad de pago')}}</li>
                                      <li class="list-group-item datos">
                                        <div class="dato-destacado">{{dataReserva.cli_tipo_pago}}</div>
                                      </li>
                                  </ul>
                                </div>
                                <div class="col-xs-6">
                                  <ul class="list-group">
                                      <li class="list-group-item active">{{LG('Datos de la reserva')}}</li>
                                      <li class="list-group-item datos">
                                        <div class="dato-destacado">#{{dataReserva.referencia}}</div>
                                        <div class="dato-destacado">{{dataReserva.evento}}</div>
                                        <div>Fecha del evento: {{dataReserva.fecha}}</div>
                                        <div>Sesión del evento: {{dataReserva.sesion}}</div>
                                        <div>Adultos: {{dataReserva.adultos}}</div>
                                        <div>Niños: {{dataReserva.ninios}}</div>
                                        <!-- <div>Niños gratis: {{dataReserva.ninos_gratis}}</div> -->
                                      </li>
                                      <li class="list-group-item active font-bold font-20">
                                        Total
                                        <div class="pull-right">{{dataReserva.precio}} €</div>
                                      </li>
                                  </ul>
                                  <div class="clearfix"></div>
                                </div>
                              </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="forma_pago_seleccionada && forma_pago_seleccionada.slug == 'stripe'">
                        <div id="content-resumen-form" class="row" style="text-align: center;">
                            <h1>{{LG('¡Gracias por tu reserva!')}}</h1>
                            <p>{{LG('A continuación te mostramos los detalles de tu reserva')}}:</p>
                            <div class="row row-small datos-reserva" *ngIf="dataReserva && dataReserva.id">
                              <div class="col-xs-6">
                                <ul class="list-group">
                                    <li class="list-group-item active">{{LG('Tus datos')}}</li>
                                    <li class="list-group-item datos">
                                      <div>{{dataReserva.cli_nombre}} {{dataReserva.cli_apellidos}}</div>
                                      <div *ngIf="dataReserva.cli_empresa">{{dataReserva.cli_empresa}}<span *ngIf="dataReserva.cli_nifcif">, {{dataReserva.cli_nifcif}}</span></div>
                                      <div>{{dataReserva.cli_direccion}}<span *ngIf="dataReserva.cli_codpostal">, {{dataReserva.cli_codpostal}}</span></div>
                                      <div>{{dataReserva.cli_localidad}}<span *ngIf="dataReserva.cli_pais">, {{dataReserva.cli_pais}}</span></div>
                                      <div *ngIf="dataReserva.cli_email"><i class="fa fa-envelope-o"></i> {{dataReserva.cli_email}}</div>
                                      <div *ngIf="dataReserva.cli_telefono"><i class="fa fa-phone"></i> {{dataReserva.cli_telefono}}</div>
                                      <div *ngIf="dataReserva.cli_notas"><br>{{dataReserva.cli_notas}}</div>
                                    </li>
                                </ul>
                                <ul class="list-group">
                                    <li class="list-group-item active">{{LG('Modalidad de pago')}}</li>
                                    <li class="list-group-item datos">
                                      <div class="dato-destacado">{{dataReserva.cli_tipo_pago}}</div>
                                    </li>
                                </ul>
                              </div>
                              <div class="col-xs-6">
                                <ul class="list-group">
                                    <li class="list-group-item active">{{LG('Datos de la reserva')}}</li>
                                    <li class="list-group-item datos">
                                      <div class="dato-destacado">#{{dataReserva.referencia}}</div>
                                      <div class="dato-destacado">{{dataReserva.evento}}</div>
                                      <div>Fecha del evento: {{dataReserva.fecha_format}}</div>
                                      <div>Sesión del evento: {{dataReserva.sesion}}</div>
                                      <div>Adultos: {{dataReserva.adultos}}</div>
                                      <div>Niños: {{dataReserva.ninos}}</div>
                                      <div>Niños gratis: {{dataReserva.ninos_gratis}}</div>
                                    </li>
                                    <li class="list-group-item active font-bold font-20">
                                      Total
                                      <div class="pull-right">{{dataReserva.total_format}} €</div>
                                    </li>
                                </ul>
                                <div class="clearfix"></div>
                              </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="modal-footer col-md-12 col-xs-12 padding-55-70">
                    <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
                    <button type="button" class="btn btn-primary" id="btn-guardar-reserva" onclick="window.location.reload()">{{LG('Realizar otra reserva')}}</button>
                </div>
              </div>
              <div id="content-paso-error" class="row modal-body" style="display: none;">
                <div class="col-md-12 col-xs-12">
                    <div class="row display-flex width-fit-auto">
                        <!-- <div class="col-lg-3"><h3 class="title-paso">1. Fecha y entradas</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">2. Tus datos</h3></div>
                        <div class="col-lg-3"><h3 class="title-paso">3. Reserva completada</h3></div> -->
                        <h3 class="title-paso anterior">1. {{LG('Fecha y entradas')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                        <h3 class="title-paso anterior">2. {{LG('Tus datos')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                        <h3 class="title-paso active error">3. {{LG('Reserva completada')}} <i class="fa fa-times" aria-hidden="true"></i></h3>
                    </div>
                    <div id="content-resumen-form" class="row">
                    </div>
                </div>
                <div class="modal-footer col-md-12 col-xs-12 padding-55-70">
                    <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
                    <button type="button" class="btn btn-white" id="btn-anterior" (click)="VolverPaso2()">{{LG('Anterior')}}</button>
                </div>
              </div>
            </div>
            </div>
        </div>
    </div>
</div>