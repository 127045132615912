import { ApplicationRef, Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;

declare let LANG: any;
declare let L: any;

@Component({
  selector: 'app-test-conversiones-meta',
  templateUrl: './test-conversiones-meta.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TestConversionesMeta implements OnInit {
  public L:any = L;
  public data:any = {};
  public redsysparameters:string = '';
  public redsysorderid:string = '';
  public orderid:string = '';
  public token:string = '';

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    let data = {
      id_evento: 2,
      cli_email: 'eblanco@pecesgordos.es',
      cli_telefono: '617797743',
      total: 90,
      
    }
    this.globals.Api('/send-conversion-meta-boton',data).subscribe(result => {
      console.log(result);
    });
  }
}
